import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icMinimize from '@iconify/icons-ic/twotone-minimize';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icVerticalSplit from '@iconify/icons-ic/round-vertical-split';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icAddLocation from '@iconify/icons-ic/twotone-add-location';
import icPortRange from '@iconify/icons-ic/settings-input-component';
import icId from '@iconify/icons-ic/fingerprint';
import icProtocol from '@iconify/icons-ic/settings-ethernet';
import icComment from '@iconify/icons-ic/comment';
import icIp from '@iconify/icons-ic/dns';
import icUpload from '@iconify/icons-ic/cloud-upload';
import icMenu from '@iconify/icons-ic/menu';
import icPI from '@iconify/icons-ic/power-input';
import icLs from '@iconify/icons-ic/linear-scale';
import icPv from '@iconify/icons-ic/personal-video';
import icSis from '@iconify/icons-ic/settings-input-svideo';
import icBr from '@iconify/icons-ic/baseline-refresh';
import icSsc from '@iconify/icons-ic/stop-screen-share';
import icCloud from '@iconify/icons-ic/cloud';
import icAutoRenew from '@iconify/icons-ic/autorenew';
import icZoomIn from '@iconify/icons-ic/zoom-in';
import icZoomOut from '@iconify/icons-ic/zoom-out';
// import icAutoAwesomeMosaic from '@iconify/icons-ic/baseline-auto-awesome-mosaic';
// import icRoundAutoMosaic from '@iconify/icons-ic/round-auto-awesome-mosaic';
import icSettingOutline from '@iconify/icons-ic/outline-settings';
import icSave from '@iconify/icons-ic/save';
import icReorder from '@iconify/icons-ic/reorder';
import icLaunch from '@iconify/icons-ic/launch';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icDescription from '@iconify/icons-ic/twotone-description';
import icGroup from '@iconify/icons-ic/twotone-group';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import icCloudOff from '@iconify/icons-ic/twotone-cloud-off';
import icTimer from '@iconify/icons-ic/twotone-timer';
import icSecurity from '@iconify/icons-ic/security';
import icAttachMoney from '@iconify/icons-ic/attach-money';
import icVisibility from '@iconify/icons-ic/visibility';
import icVisibilityOff from '@iconify/icons-ic/visibility-off';
import icBusiness from '@iconify/icons-ic/business';
import icEmail from '@iconify/icons-ic/email';
import icCheck from '@iconify/icons-ic/check';
import icClear from '@iconify/icons-ic/clear';
import icLoyalty from '@iconify/icons-ic/loyalty';
import icCreate from '@iconify/icons-ic/create';
import icAllOut from '@iconify/icons-ic/all-out';
import icDountLarge from '@iconify/icons-ic/donut-large';
import icRoundInfo from '@iconify/icons-ic/round-info';
import icReplay from '@iconify/icons-ic/replay';
import icCheckBox from '@iconify/icons-ic/check-box';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icOutlineInfo from '@iconify/icons-ic/outline-info';
import icArrowUp from '@iconify/icons-ic/keyboard-arrow-up';
import icArrowDown from '@iconify/icons-ic/keyboard-arrow-down';
import icOutlineClose from '@iconify/icons-ic/outline-close';
import icRoundClose from '@iconify/icons-ic/close';
import icDeviceHub from '@iconify/icons-ic/device-hub';
import icArrowBack from '@iconify/icons-ic/arrow-back';
import icArrowForward from '@iconify/icons-ic/arrow-forward';
import icHighlightOff from '@iconify/icons-ic/highlight-off';
import icMagnifyingGlass from '@iconify/icons-ic/find-in-page';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import icExitToApp from '@iconify/icons-ic/exit-to-app';
import icLink from '@iconify/icons-ic/link';
import icDataUsage from '@iconify/icons-ic/data-usage';
import icHelp from '@iconify/icons-ic/help-outline';
import icDollar from '@iconify/icons-fa-solid/dollar-sign';
import icDragIndicator from '@iconify/icons-ic/round-drag-indicator';
import icViewArray from '@iconify/icons-ic/view-array';
import icBrHigh from '@iconify/icons-ic/brightness-high';
import icBrMedium from '@iconify/icons-ic/brightness-medium';
import icBrLow from '@iconify/icons-ic/brightness-low';
import icStar from '@iconify/icons-ic/twotone-star';
import icFormatSpacing from '@iconify/icons-ic/format-line-spacing';
import icBackArrow from '@iconify/icons-ic/arrow-back';
import icForwardArrow from '@iconify/icons-ic/arrow-forward';
import icDone from '@iconify/icons-ic/done';
import icUpdate from '@iconify/icons-ic/twotone-update';
import icDomain from '@iconify/icons-ic/domain';
import icAssignment from '@iconify/icons-ic/assignment';
import icDropUp from '@iconify/icons-ic/arrow-drop-up';
import icDropDown from '@iconify/icons-ic/arrow-drop-down';
import icFormatList from '@iconify/icons-ic/format-list-numbered';
import icEyes from '@iconify/icons-ic/remove-red-eye';
import icViewList from '@iconify/icons-ic/view-list';
import icExternalLink from '@iconify/icons-fa-solid/external-link-alt';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icHome from '@iconify/icons-ic/home';
import icOpenInNew from '@iconify/icons-ic/open-in-new';
import icBaselinePictureAsPdf from '@iconify/icons-ic/baseline-picture-as-pdf';
import twotoneTextSnippet from '@iconify/icons-ic/twotone-text-snippet';
import twotoneInsertDriveFile from '@iconify/icons-ic/twotone-insert-drive-file';
import icAttachFile from '@iconify/icons-ic/twotone-attach-file';
import icLock from '@iconify/icons-ic/lock';
import icCancel from '@iconify/icons-ic/cancel';
import icLockOpen from '@iconify/icons-ic/lock-open';
import icHistory from '@iconify/icons-ic/history';
import icDehaze from '@iconify/icons-ic/dehaze';
import icWatch from '@iconify/icons-ic/watch-later';
import icUndo from '@iconify/icons-ic/undo';
import icReport from '@iconify/icons-ic/report';
import icAssessment from '@iconify/icons-ic/assessment';
import icInfo from '@iconify/icons-ic/info';
import icTwotoneCalendarToday from '@iconify/icons-ic/twotone-calendar-today';
import icInsertComment from '@iconify/icons-ic/twotone-insert-comment';
import icImage from '@iconify/icons-ic/twotone-image';
import icSubject from '@iconify/icons-ic/twotone-subject';
import icNoteAdd from '@iconify/icons-ic/note-add';
import icCollections from '@iconify/icons-ic/collections';
import icSupervisorAccount from '@iconify/icons-ic/supervisor-account';
import icFALink from '@iconify/icons-fa-solid/link';
import icFAUNLink from '@iconify/icons-fa-solid/unlink';
import icDatabase from '@iconify/icons-fa-solid/database';
import icFileAlt from '@iconify/icons-fa-solid/file-alt';
import icAlignJustify from '@iconify/icons-fa-solid/align-justify';
import icBars from '@iconify/icons-fa-solid/bars';
import icWarning from '@iconify/icons-ic/warning';
import icTextFields from '@iconify/icons-ic/text-fields';
import icRefresh from '@iconify/icons-ic/refresh';
import icWordFile from '@iconify/icons-fa-solid/file-word';
import icLayers from '@iconify/icons-ic/layers';
import icFileUpload from '@iconify/icons-fa-solid/file-upload';
import icNetworkCheck from '@iconify/icons-ic/network-check';
import icTasks from '@iconify/icons-fa-solid/tasks';
import icPhoneLinkLock from '@iconify/icons-ic/phonelink-lock';
import icDataCenterSite from '@iconify/icons-fa-solid/globe';
import icDevice from '@iconify/icons-fa-solid/sitemap';
import icVendors from '@iconify/icons-fa-solid/handshake';
import icDeviceType from '@iconify/icons-fa-solid/cubes';
import icOrganization from '@iconify/icons-fa-solid/network-wired';
import icProject from '@iconify/icons-fa-solid/project-diagram';
import icEnvironment from '@iconify/icons-fa-solid/server';
import icPolicy from '@iconify/icons-fa-solid/scroll';
import icTopology from '@iconify/icons-fa-solid/puzzle-piece';
import icConnection from '@iconify/icons-fa-solid/network-wired';
import icSync from '@iconify/icons-fa-solid/sync';
export class Icons {
    // Icons
    icSync = icSync;
    icTopology = icTopology;
    icConnection = icConnection;
    icPolicy = icPolicy;
    icDeviceType = icDeviceType;
    icVendors = icVendors;
    icDevice = icDevice;
    icDataCenterSite = icDataCenterSite;
    icOrganization = icOrganization;
    icProject = icProject;
    icEnvironment = icEnvironment;
    icPhoneLinkLock = icPhoneLinkLock;
    icNetworkCheck = icNetworkCheck;
    icTasks = icTasks;
    icFileUpload = icFileUpload;
    icLayers = icLayers;
    icWordFile = icWordFile;
    icRefresh = icRefresh;
    icTextFields = icTextFields;
    icTableChart = icTableChart;
    icWarning = icWarning;
    icBars = icBars;
    icAlignJustify = icAlignJustify;
    icFileAlt = icFileAlt
    icDatabase = icDatabase;
    icFALink = icFALink;
    icFAUNLink = icFAUNLink;
    icSupervisorAccount = icSupervisorAccount;
    icCollections = icCollections;
    icNoteAdd = icNoteAdd;
    icSubject = icSubject;
    icImage = icImage;
    icInsertComment = icInsertComment;
    icTwotoneCalendarToday = icTwotoneCalendarToday;
    icInfo = icInfo;
    icAssessment = icAssessment;
    icReport = icReport;
    icUndo = icUndo;
    icMinimize = icMinimize;
    icWatch = icWatch;
    icDehaze = icDehaze;
    icHistory = icHistory;
    icLockOpen = icLockOpen;
    icCancel = icCancel;
    icLock = icLock;
    icAttachFile = icAttachFile;
    icBaselinePictureAsPdf = icBaselinePictureAsPdf;
    twotoneTextSnippet = twotoneTextSnippet;
    twotoneInsertDriveFile = twotoneInsertDriveFile;
    icOpenInNew = icOpenInNew;
    icHome = icHome
    icArrowDropDown = icArrowDropDown;
    icUpdate = icUpdate;
    icDropUp = icDropUp;
    icFormatList = icFormatList;
    icDropDown = icDropDown;
    icEyes = icEyes;
    icForwardArrow = icForwardArrow;
    icDomain = icDomain;
    icAssignment = icAssignment;
    icDone = icDone;
    icBackArrow = icBackArrow;
    icFormatSpacing = icFormatSpacing;
    icZoomIn = icZoomIn;
    icZoomOut = icZoomOut;
    icBrHigh = icBrHigh;
    icBrMedium = icBrMedium;
    icBrLow = icBrLow;
    icDragIndicator = icDragIndicator;
    icHelp = icHelp;
    icDataUsage = icDataUsage;
    icLink = icLink;
    icFileCopy = icFileCopy;
    icDeviceHub = icDeviceHub;
    icExitToApp = icExitToApp;
    icClear = icClear;
    icCheck = icCheck;
    icPhone = icPhone;
    icMail = icMail;
    icMap = icMap;
    icEdit = icEdit;
    icSearch = icSearch;
    icDelete = icDelete;
    icAdd = icAdd;
    icFilterList = icFilterList;
    icMoreHoriz = icMoreHoriz;
    icFolder = icFolder;
    icMoreVert = icMoreVert;
    icClose = icClose;
    icPrint = icPrint;
    icDownload = icDownload;
    icPerson = icPerson;
    icMyLocation = icMyLocation;
    icLocationCity = icLocationCity;
    icEditLocation = icEditLocation;
    icId = icId;
    icProtocol = icProtocol;
    icComment = icComment;
    icIp = icIp;
    icPortRange = icPortRange;
    icUpload = icUpload;
    icMenu = icMenu;
    icPI = icPI;
    icLs = icLs;
    icPv = icPv;
    icSis = icSis;
    icBr = icBr;
    icSsc = icSsc;
    icCloud = icCloud;
    icAutoRenew = icAutoRenew;
    icAutoAwesomeMosaic = icAutoRenew;
    icRoundAutoMosaic = icAutoRenew;
    icSettingOutline = icSettingOutline;
    icSave = icSave;
    icReorder = icReorder;
    icLaunch = icLaunch;
    icAddLocation = icAddLocation;
    icDoneAll = icDoneAll;
    icDescription = icDescription;
    icGroup = icGroup;
    icPageView = icPageView;
    icCloudOff = icCloudOff;
    icTimer = icTimer;
    icSecurity = icSecurity;
    icAttachMoney = icAttachMoney;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;
    icBusiness = icBusiness;
    icEmail = icEmail;
    icLoyalty = icLoyalty;
    icCreate = icCreate;
    icAllOut = icAllOut;
    icDountLarge = icDountLarge;
    icRoundInfo = icRoundInfo;
    icReplay = icReplay;
    icCheckBoxOutline = icCheckBox;
    icCheckCircle = icCheckCircle;
    icOutlineInfo = icOutlineInfo;
    icOutlineClose = icOutlineClose;
    icRoundClose = icRoundClose;
    icArrowBack = icArrowBack;
    icArrowForward = icArrowForward;
    icArrowUp = icArrowUp;
    icArrowDown = icArrowDown;
    icHighlightOff = icHighlightOff;
    icMagnifyingGlass = icMagnifyingGlass;
    icDollar = icDollar;
    icViewArray = icViewArray;
    icStar = icStar;
    icVerticalSplit = icVerticalSplit;
    icViewList = icViewList;
    icExternalLink = icExternalLink;
}
