export const Breadcrumbs = [
  {
    PageID: 1,
    PageName: 'Dashboard',
    Navigate: ['./'],
    Crumbs: ['Dashboard']
  },
  {
    PageID: 2,
    PageName: 'Designer',
    Navigate: ['./', '/network-management/blueprintdesigner/create'],
    Crumbs: ['Blueprints', 'Designer'],
    FeatureId: ['626ab5cde931afcc68a273ed', '626ab5cde931afcc68a273ed']
  },
  {
    PageID: 3,
    PageName: 'VDC Catalog',
    Navigate: ['./', '/blueprintdesigner/list'],
    Crumbs: ['Blueprints', 'VDC Catalog'],
    FeatureId: ['61df2aa06c78e2d198f631ff']
  },
  {
    PageID: 4,
    PageName: 'Resource Templates',
    Navigate: ['./', '/network-management/blueprint-templates'],
    Crumbs: ['Blueprints', 'Resource Templates'],
    FeatureId: ['626ab5cde931afcc68a273ee']
  },
  {
    PageID: 5,
    PageName: 'Security Blueprint',
    Navigate: ['./', '/security/application'],
    Crumbs: ['Security', 'Security Blueprint'],
    FeatureId: ['613f15da550a047115dacf91']
  },
  {
    PageID: 6,
    PageName: 'Security BP',
    Navigate: ['./', '/security/app-component'],
    Crumbs: ['Security', 'Security BP'],
    FeatureId: ['613f15da550a047115dacf92']
  },
  {
    PageID: 7,
    PageName: 'Risk Rules DB',
    Navigate: ['./', '/security/risk'],
    Crumbs: ['Security', 'Risk Rules DB'],
    FeatureId: ['613f15da550a047115dacf93']
  },
  {
    PageID: 8,
    PageName: 'Change Request',
    Navigate: ['./', '/security/change-request-board'],
    Crumbs: ['Security', 'Change Request'],
    FeatureId: ['613f15da550a047115dacf96']
  },
  {
    PageID: 9,
    PageName: 'Policy Manager',
    Navigate: ['./', '/compliance/policy-manager'],
    Crumbs: ['Compliance', 'Policy Manager'],
    FeatureId: ['62780175141ea798103743d8']
  },
  {
    PageID: 10,
    PageName: 'Benchmarks',
    Navigate: ['./', '/compliance/benchmarks'],
    Crumbs: ['Compliance', 'Benchmarks'],
    FeatureId: ['62780175141ea798103743d7']
  },
  {
    PageID: 11,
    PageName: 'Non-Compliant Reporting',
    Navigate: ['./', '/compliance/non-compliant-reporting'],
    Crumbs: ['Compliance', 'Non-Compliant Reporting'],
    FeatureId: ['62780175141ea798103743d9']
  },
  {
    PageID: 12,
    PageName: 'Migration Job',
    Navigate: ['./', '/network-management/cloudmigration/list'],
    Crumbs: ['Operations', 'Migration Job'],
    FeatureId: ['61df218fa354775fa8fa63c6']
  },
  {
    PageID: 13,
    PageName: 'Brownfield VDC Discovery',
    Navigate: ['./', '/network-management/unclaimed-vpc'],
    Crumbs: ['Operations', 'Brownfield VDC Discovery'],
    FeatureId: ['61df218fa354775fa8fa63c8']
  },
  {
    PageID: 14,
    PageName: 'VDC Inventory',
    Navigate: ['./', '/deployment/vdc-inventory'],
    Crumbs: ['Operations', 'VDC Inventory'],
    FeatureId: ['6277fe82141ea79810373de3']
  },
  {
    PageID: 15,
    PageName: 'Connectivity Manager',
    Navigate: ['./', '/network-management/network-topology'],
    Crumbs: ['Operations', 'Connectivity Manager'],
    FeatureId: ['6277fe82141ea79810373de4']
  },
  {
    PageID: 16,
    PageName: 'Cloud Errors',
    Navigate: ['./', './', '/operations/blueprint-error'],
    Crumbs: ['Operations', 'Events', 'Cloud Errors']
  },
  {
    PageID: 17,
    PageName: 'Notification',
    Navigate: ['./', './', '/application-settings/notifications'],
    Crumbs: ['Operations', 'Events', 'Notification'],
    FeatureId: ['6277fc97141ea7981037359b']
  },
  {
    PageID: 18,
    PageName: 'Addresses',
    Navigate: ['./', './', '/security/address'],
    Crumbs: ['Operations', 'Pools', 'Addresses'],
    FeatureId: ['627800b3141ea7981037402d']
  },
  {
    PageID: 19,
    PageName: 'Address Groups',
    Navigate: ['./', './', '/security/address-group'],
    Crumbs: ['Operations', 'Pools', 'Address Groups'],
    FeatureId: ['627800b3141ea7981037402e']
  },
  {
    PageID: 20,
    PageName: 'IP Pools',
    Navigate: ['./', './', '/network-management/ip-pool'],
    Crumbs: ['Operations', 'Pools', 'IP Pools'],
    FeatureId: ['627800b3141ea7981037402f']
  },
  {
    PageID: 21,
    PageName: 'Roles',
    Navigate: ['./', '/application-settings/roles'],
    Crumbs: ['Platform Settings', 'Roles'],
    FeatureId: ['61bc6b458047717c756b29a7']
  },
  {
    PageID: 22,
    PageName: 'Users',
    Navigate: ['./', '/application-settings/users'],
    Crumbs: ['Platform Settings', 'Users'],
    FeatureId: ['61bc6b458047717c756b29a5']
  },
  {
    PageID: 23,
    PageName: 'Groups',
    Navigate: ['./', '/application-settings/groups'],
    Crumbs: ['Platform Settings', 'Groups'],
    FeatureId: ['61bc6b458047717c756b29a6']
  },
  {
    PageID: 24,
    PageName: 'Cloud Connect',
    Navigate: ['./', '/application-settings/cloud-connect'],
    Crumbs: ['Platform Settings', 'Cloud Connect'],
    FeatureId: ['61bc6b458047717c756b29a8']
  },
  {
    PageID: 25,
    PageName: 'Operational Settings',
    Navigate: ['./', '/platform-settings/organization-setting'],
    Crumbs: ['Platform Settings', 'Operational Settings'],
    FeatureId: ['61bc6b458047717c756b29a9']
  },
  {
    PageID: 26,
    PageName: 'AD Center',
    Navigate: ['./', '/application-settings/ad-center-list'],
    Crumbs: ['Platform Settings', 'AD Center'],
    FeatureId: ['61bc6b458047717c756b29a4']
  },
  {
    PageID: 27,
    PageName: 'Add Collection',
    Navigate: ['./', '/platform-settings/collection'],
    Crumbs: ['Developer Mode', 'Add Collection'],
    FeatureId: ['61111dbcf9546dbc7f78cf5c']
  },
  {
    PageID: 28,
    PageName: 'Modify Collection',
    Navigate: ['./', '/platform-settings/cloud-setting'],
    Crumbs: ['Developer Mode', 'Modify Collection'],
    FeatureId: ['61111dbcf9546dbc7f78cf5d']
  },
  {
    PageID: 29,
    PageName: 'Service Dependency Designer',
    Navigate: ['./', '/platform-settings/servicedependencydesigner'],
    Crumbs: ['Developer Mode', 'Service Dependency Designer'],
    FeatureId: ['61111dbcf9546dbc7f78cf5e']
  },
  {
    PageID: 30,
    PageName: 'Modules',
    Navigate: ['./', '/platform-settings/module'],
    Crumbs: ['Developer Mode', 'Modules'],
    FeatureId: ['61111dbcf9546dbc7f78cf5f']
  },
  {
    PageID: 31,
    PageName: 'Attribute Type',
    Navigate: ['./', '/platform-settings/attributetype'],
    Crumbs: ['Developer Mode', 'Attribute Type'],
    FeatureId: ['61111dbcf9546dbc7f78cf60']
  },
  {
    PageID: 32,
    PageName: 'Feature Mapping',
    Navigate: ['./', '/platform-settings/featureMappingList'],
    Crumbs: ['Developer Mode', 'Feature Mapping'],
    FeatureId: ['61111dbcf9546dbc7f78cf61']
  },
  {
    PageID: 33,
    PageName: 'Template Creator',
    Navigate: ['./', '/platform-settings/template-creator'],
    Crumbs: ['Developer Mode', 'Template Creator'],
    FeatureId: ['61111dbcf9546dbc7f78cf62']
  },
  {
    PageID: 43,
    PageName: 'Assessment History',
    Navigate: ['./', '/compliance/history'],
    Crumbs: ['Compliance', 'Assessment History'],
    FeatureId: ['638de223e238bf1e8bc28f3d']
  },
  {
    PageID: 45,
    PageName: 'Integrations',
    Navigate: ['./', '/application-settings/integrations'],
    Crumbs: ['Platform Settings', 'Integrations'],
    FeatureId: ['647fc2b75526f41beb1d3897']
  },
  {
    PageID: 46,
    PageName: 'Meta Managment',
    Navigate: ['./', '/network-management/meta-management'],
    Crumbs: ['Blueprints', 'Meta Managment'],
    FeatureId: ['64f188033544b2f8e5ea2af1']
  },
  {
    PageID: 47,
    PageName: 'Bulk Assesment',
    Navigate: ['./', '/compliance/bulk-assessment'],
    Crumbs: ['Compliance', 'Bulk Assesment'],
    FeatureId: ['638784ed0a504e73fd86ab1c']
  },
  // For Vex-Wizard
  {
    PageID: 34,
    Crumbs: [
      { Label: 'Blueprints', Value: '' },
      { Label: 'Resource Templates', Value: '/network-management/blueprint-templates' }
    ]
  },
  {
    PageID: 35,
    Crumbs: [
      { Label: 'Blueprints', Value: '' },
      { Label: 'VDC Catalog', Value: '/blueprintdesigner/list' }
    ]
  },
  {
    PageID: 36,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'Migration', Value: '/network-management/cloudmigration/list' }
    ]
  },
  {
    PageID: 37,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'Brownfield VDC Discovery', Value: '/network-management/unclaimed-vpc' }
    ]
  },
  {
    PageID: 38,
    Crumbs: [
      { Label: 'Platform Settings', Value: '' },
      { Label: 'Cloud Connect', Value: '/application-settings/cloud-connect' }
    ]
  },
  {
    PageID: 39,
    PageName: 'Roles',
    Navigate: ['./', '/application-settings/roles'],
    Crumbs: ['Platform Settings', 'Roles']
  },
  {
    PageID: 40,
    Crumbs: [
      { Label: 'Security', Value: '' },
      { Label: 'Compliance', Value: '' },
      { Label: 'Policy Manager', Value: '/compliance/policy-manager' }
    ]
  },
  {
    PageID: 41,
    Crumbs: [
      { Label: 'Security', Value: '' },
      { Label: 'Compliance', Value: '' },
      { Label: 'Benchmarks', Value: '/compliance/benchmarks' }
    ]
  },
  {
    PageID: 42,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'VDC Inventory', Value: '/deployment/vdc-inventory' }
    ]
  },
  {
    PageID: 49,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'Connectivity Manager', Value: '/network-management/network-topology' }
    ]
  },
  {
    PageID: 44,
    Crumbs: [
      { Label: 'Platform Settings', Value: '' },
      { Label: 'Integration', Value: '/application-settings/integrations' }
    ]
  },
  {
    PageID: 48,
    Crumbs: [
      { Label: 'Security', Value: '' },
      { Label: 'Security Blueprint', Value: '/security/application' }
    ]
  },
  {
    PageID: 50,
    PageName: 'Azure Resource Manager',
    Navigate: ['./', '/network-management/azure-manager'],
    Crumbs: ['Operations', 'Azure Resource Manager'],
    FeatureId: ['65e225ce8136a382b076bdc1']
  },
  {
    PageID: 51,
    PageName: 'Deployment',
    Navigate: ['./', '/blueprintdesigner/list', './'],
    Crumbs: ['Blueprints', 'VDC Catalog', 'Deployment'],
    FeatureId: ['61df2aa06c78e2d198f631ff']
  },
  {
    PageID: 52,
    PageName: 'Device Inventory',
    Navigate: ['./', './', '/network-management/devices'],
    Crumbs: ['Operations', 'DC', 'Device Inventory'],
    FeatureId: ['664fbd3eea6a069056091354']
  },
  {
    PageID: 53,
    PageName: 'Location',
    Navigate: ['./', './', '/network-management/data-center-sites'],
    Crumbs: ['Operations', 'DC', 'Location'],
    FeatureId: ['664fbd95ea6a069056091d51']
  },
  
  {
    PageID: 54,
    PageName: 'Device Types',
    Navigate: ['./', '/platform-settings/device-types'],
    Crumbs: ['Developer Mode', 'Device Types'],
    FeatureId: ['665774e70b2fff9f9363b20b']
  },
  {
    PageID: 55,
    PageName: 'Manufacturers',
    Navigate: ['./', '/platform-settings/manufacturers'],
    Crumbs: ['Developer Mode', 'Manufacturers'],
    FeatureId: ['665774e70b2fff9f9363b20f']
  },
  {
    PageID: 56,
    PageName: 'Device Authentication',
    Navigate: ['./', './', '/application-settings/device-authentication'],
    Crumbs: ['Operations', 'DC', 'Device Authentication'],
    FeatureId: ['6676abbcf74f794738b6172a']
  },
  {
    PageID: 57,
    PageName: 'Workflow Templates',
    Navigate: ['./', '/network-management/workflow-template'],
    Crumbs: ['Blueprints', 'Workflow Templates'],
    FeatureId: ['664fbd3eea6a069056091354']
  },
  {
    PageID: 58,
    PageName: 'Terraform Deployment',
    Navigate: ['./', '/deployment/vdc-inventory', './'],
    Crumbs: ['Operations', 'Inventory', 'Deployment Code'],
    FeatureId: ['61df2aa06c78e2d198f631ff']
  },
  {
    PageID: 59,
    PageName: 'Organizations',
    Navigate: ['./', '/platform-settings/organizations'],
    Crumbs: ['Developer Mode', 'Organizations'],
    FeatureId: ['66bbd48378528508fdef6db4']
  },
  {
    PageID: 60,
    PageName: 'Deployment',
    Navigate: ['./', '/network-management/workflow-template', './'],
    Crumbs: ['Blueprints', 'Workflow Template', 'Deployment'],
    FeatureId: ['66851c952e63ac5bcee14357']
  },
  {
    PageID: 61,
    PageName: 'Workflow Inventories',
    Navigate: ['./', '/network-management/workflow-inventories'],
    Crumbs: ['Operations', 'Workflow Inventories'],
    FeatureId: ['66eaaecac46d1c7e390f266e']
  },
  {
    PageID: 62,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'DC', Value: '' },
      { Label: 'Location', Value: '/network-management/data-center-sites' }
    ]
  },
  {
    PageID: 63,
    Crumbs: [
      { Label: 'Operations', Value: '' },
      { Label: 'DC', Value: '' },
      { Label: 'Device', Value: '/network-management/devices' }
    ]
  },
  
];

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  constructor() {}

  getBreadcrumbs(Id: number) {
    return Breadcrumbs.find((d) => d.PageID === Id);
  }

  getWizardBreadcrumbs(Id: number) {
    return Breadcrumbs.find((d) => d.PageID === Id).Crumbs;
  }

  getBreadcrumbsByFeatureId(Id: string) {
    return Breadcrumbs.find((d) => d.FeatureId && d.FeatureId.findIndex((x) => x == Id) != -1)
      ?.Crumbs;
  }
}
